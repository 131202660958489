import * as React from "react";
import CurrencyFormat from "react-currency-format";

import { isNegative, valueAsNum } from "../../../utils/FormatHelpers";

export interface CurrencyProps {
    amount?: number | string;
    className?: string;
    isInput?: boolean;
    onChange?: (value?: number | string) => void;
    withoutPrefix?: boolean;
}

type Props = CurrencyProps;
/**
 *
 * @param props
 */
export const Currency: React.FunctionComponent<Props> = (props) => {
    if (props.amount === null || props.amount === undefined) {
        return null;
    }
    const amountAsNum = valueAsNum(props.amount);
    return (
        !!props.amount && isNegative(amountAsNum) ? (
            <>
                <span className={props.className}>€&nbsp;</span>
                <CurrencyFormat
                    value={amountAsNum}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType={!!props.isInput ? "input" : "text"}
                    className={props.className}
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={props.onChange}
                />
            </>) : (
            <CurrencyFormat
                value={amountAsNum}
                thousandSeparator={"."}
                decimalSeparator={","}
                prefix={props.withoutPrefix ? "" : "€\xa0"}
                displayType={!!props.isInput ? "input" : "text"}
                className={props.className}
                decimalScale={2}
                fixedDecimalScale
                onValueChange={props.onChange}
            />
        )
    );
};
