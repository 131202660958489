import * as React from "react";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { asThemeConsumer } from "../../../theme/Theme";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    title: string;
}

/**
 *
 * @param props
 */
const FieldSetComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className,
        "scl-m-field-set",
    );

    return (
        <fieldset
            className={clazzName}
        >
            <legend className="scl-m-field-set__title">{props.title}</legend>
            <div className="scl-m-field-set__content">
                {props.children}
            </div>
        </fieldset>
    );
};

/**
 *
 */
export const FieldSet = asThemeConsumer(FieldSetComp);
