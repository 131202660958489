import * as React from "react";
import DatePicker, { ReactDatePicker, ReactDatePickerProps, registerLocale } from "react-datepicker";
import moment from "moment";
import nl from "date-fns/locale/nl";
import { BaseConsumer } from "../03-base/Base";
registerLocale("nl", nl);

export interface DateInputProps extends Pick<ReactDatePickerProps, "popperPlacement"> {
    className?: string;
    value?: string | number;
    onChange?: (value?: string) => void;
    minDate?: Date;
    maxDate?: Date;
    disabled?: boolean;
    required?: boolean;
    dateFormat?: string;
    placeholderText?: string;
}

type Props = DateInputProps;

const onChangeDate = (date?: Date | null, onChange?: (value?: string) => void) => {
    if (!!onChange) {
        onChange(
            date ? moment(date).format("YYYY-MM-DD") : undefined);
    }
};

/**
 *
 * @param props
 */
export const DateInput: React.FunctionComponent<Props> = (props) => {
    const inputRef = React.useRef<ReactDatePicker | null>(null);
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if(e.code === "Tab") {
            return inputRef.current?.setOpen(false);
        }
    };
    return (
        <BaseConsumer>{({ getLanguage }) =>
            (
                <DatePicker
                    ref={inputRef}
                    selected={!!props.value && typeof props.value === "string" ? new Date(props.value) : undefined}
                    dateFormat={props.dateFormat ?? "dd-MM-yyyy"}
                    onChange={(value: Date | null) => onChangeDate(value, props.onChange)}
                    className={props.className}
                    isClearable={!!props.disabled ? false : true}
                    placeholderText={props.placeholderText ?? "DD-MM-JJJJ"}
                    popperPlacement={props.popperPlacement || "top"}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    disabled={!!props.disabled}
                    required={props.required}
                    locale={getLanguage() === "nl" ? getLanguage() : undefined}
                    strictParsing
                    onKeyDown={handleKeyDown}
                    enableTabLoop={false}
                />)
        }
        </BaseConsumer>
    );
};

export const testUtil = {
    onChangeDate: onChangeDate,
};
